<template>
    <div>
        <el-card class="mt-2 ml-4 mr-4 bg-white border-white">

            <div style="width: 100%; margin: auto; " class="font-semibold text-lg">
                <el-divider direction="vertical" style="color: #7d89c3;width: 5px;"></el-divider>园区管理
                <el-button type="primary" style="background-color: #000000a6;color: white;margin-left: 2rem;border:0px solid"
                    size="small" @click="update" icon="el-icon-sort">更新</el-button>
                    <div class="flex justify-end text-xs mt-4">*最近一个更新的时间为：{{tableData[0].update_time}}</div>
            </div>
            <!-- 表格 -->
            <el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
                <el-tab-pane label="全部" name="first">
                    <el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
                        <el-table-column prop="id" label="" min-width="5%">
                        </el-table-column>
                        <el-table-column prop="name" label="园区名称" min-width="25%">
                        </el-table-column>
                        <el-table-column prop="count" label="班级数量" min-width="15%">
                        </el-table-column>
                        <el-table-column prop="update_time" label="更新时间" min-width="20%">
                        </el-table-column>
                        <el-table-column label="班级考勤" min-width="15%">
                            <template slot-scope="scope">
                                <el-button size="small" type="text" @click="classDetail(scope.row)">详情</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column label="学生详情" min-width="15%">
                            <template slot-scope="scope">
                                <el-button size="small" type="text" @click="studentDetail(scope.row)">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
					 <!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
                </el-tab-pane>
            </el-tabs>
            <!-- 分页 -->
            <!-- <div class="flex  bg-white ml-4 mt-6 pt-4 pb-4">
                <div></div>
                <Pagination :currentPage="currentPage" :total="total" @pageChange="pageChange" />
            </div> -->
        </el-card>
    </div>
</template>

<script>
    import Pagination from "../../components/Pagination/pagination";
    export default {

        data() {
            return {
                form: {
                    imgUrl: "", //图片字段
                    platName: "", //自定义字段
                },
                activeName: 'first',
                labelWidth: "100px",
                searchData: {
                    name: ''
                },
                tableData: [],
                // 对话框
                isAdd: false, //区分添加和编辑
                dialog: false,
                // 分页
                total: 0,
                currentPage: 1,
                school_id: ''
            };
        },

        computed: {},
        components: {
            Pagination
        },
        watch: {},
        mounted() {
            this.school_id = window.sessionStorage.getItem("school_id")
            this.getList()
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            //更新
            update() {
               this.$confirm("是否更新", "提示", {
                   confirmButtonText: "确定",
                   cancelButtonText: "取消",
                   type: "warning",
               }).then(async () => {
                   const loading = this.$loading({
                       lock: true,
                       text: '数据导入一定时间，请耐心等待',
                       spinner: 'el-icon-loading',
                       background: 'rgba(0, 0, 0, 0.7)'
                   });
                   const res = await this.http.post("admin/impcampus/updateData", {
                       school_id: this.school_id,
                   })
                   if (res.code == 200) {
                       loading.close()
                       this.$message({
                           showClose: true,
                           message: '更新成功',
                           type: 'success'
                       });
                       this.getList()
                   }else if(res.code==400){
                       loading.close()
                       this.getList()
                       this.$message.error(res.msg)
                   }else{
                       this.$message.error("错误，请重试")
                       loading.close()
                       this.getList()
                   }
               })
                   .catch(() => {
                       this.$message({
                           type: "info",
                           message: "已取消更新",
                       });
                   })
            },
            //跳转到班级详情
            classDetail(row) {
                this.$router.push({ name: 'classStudent' })
                window.sessionStorage.setItem("nursery_id", row.id)
                window.sessionStorage.setItem("className", row.name)
            },
            //跳转到学生管理
            studentDetail(row) {
                window.sessionStorage.setItem("kindergarten", row.name)//幼儿园名称
                window.sessionStorage.setItem("nursery_id", row.id)
                this.$router.push({ name: 'student' })
            },
            // 获取基本数据
            async getList() {
                const RES = await this.http.post("/admin/campus/all", {
                    limit: 10,
                    page: this.currentPage,
                    school_id: this.school_id
                });
                if (RES.code == 200) {
                    this.tableData = RES.data;
                    //  this.total = RES.data.total;
                }
            },
            // 编辑
            handleEdit(row) {
                this.form = JSON.parse(JSON.stringify(row)); //编辑给表单赋值的时候，需要把数据深度拷贝
            },
            //分页
            pageChange(index) {
                this.currentPage = index._currentPage;
                this.getList();
            },
        },
    };
</script>
<style lang="scss" scoped>
    /deep/.el-dialog__headerbtn .el-dialog__close {
        color: #647d89c3;
    }

    /deep/.el-dialog {
        height: 400px;
    }



    /deep/.el-tabs__header {
        background-color: #f8f8f8;
    }

      /deep/.el-tabs__item.is-active {
        background-color: #ecedf3;
        color: #535c8d;
    }
    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }
    /deep/.el-tabs__active-bar {
        background-color: #7d89c3;
        margin-left: -1.8rem;
    }




    /deep/.el-dialog__headerbtn {
        top: 10px;
    }

    /deep/.el-dialog__title {
        color: white;
        font-size: 1rem;
    }

    /deep/.el-table th {
        background-color: white !important;
    }

    /deep/.el-tabs__nav {}

  

    /deep/.el-tabs__active-bar {
        background-color: #7d89c3;
        margin-left: -1.8rem;
    }

    /deep/.el-table td,
    .el-table th {
        text-align: center;
    }

    /deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        padding: 0 30px;
    }

    /deep/.el-table th {
        text-align: center;
        background: rgb(236, 235, 235);
    }

    /deep/ .avatar-uploader .el-upload {
        border: 1px dashed hsl(0, 0%, 85%);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: rgb(204, 204, 204);
    }

    /deep/.avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    /deep/ .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    /deep/ .avatar {
        width: 200px;
        height: 150px;
        display: block;
    }

    /deep/.el-form-item {
        margin-bottom: 5px;
    }

    /deep/.el-dialog__body {
        padding: 0px;
    }

    /deep/.el-divider--horizontal {
        margin: 0px;
    }
    /deep/.el-button--primary.is-plain{
            border-color: inherit;
    }
</style>